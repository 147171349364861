.comments-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.comments-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.comment-card {
  min-width: 100%;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  background: #f9f9f9;
}

.comment-text {
  font-size: 1.2rem;
  font-style: italic;
  color: #333;
  margin-bottom: 10px;
}

.comment-name {
  font-size: 1rem;
  font-weight: bold;
  color: #000;
}

.comment-position {
  font-size: 0.9rem;
  color: #666;
}

/* Efecto de arrastre */
.comments-container:active {
  cursor: grabbing;
}
