/* Contenedor de la página */
.pricing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Encabezado de la página */
.pricing-header {
  margin-bottom: 2rem;
  text-align: center;
}

.pricing-header h2 {
  font-size: 1.8rem;
  line-height: 1.5;
  color: #cecece;
}

/* Contenedor de las tarjetas */
.pricing-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem; /* Espacio entre las tarjetas */
  justify-content: center;
  width: 100%;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .pricing-header h2 {
    font-size: 1.5rem;
    padding: 0 1rem;
  }

  .pricing-cards-container {
    flex-direction: column; /* Apila las tarjetas */
    gap: 1rem;
    align-items: center;
  }
}
