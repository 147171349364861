.pricing-card {
  padding: 2rem;
  border-radius: 12px;
  color: #ffffff;
  text-align: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  margin: 0;
}

.pricing-tag {
  background-color: #f39c12;
  color: #fff;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  font-size: 0.85rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.price-container {
  margin: 1rem 0;
}

.old-price {
  text-decoration: line-through;
  color: #e74c3c;
  font-size: 0.85rem;
  display: block;
}

.pricing-card h1 {
  font-size: 2.2rem;
  margin: 0.5rem 0;
}

.billing-info {
  font-size: 0.85rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.monthly-price {
  font-size: 0.85rem;
  color: #e0e6e8;
  margin-bottom: 1rem;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
  text-align: left;
}

.feature-list li {
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.pricing-button {
  background-color: #e0d7f3;
  color: #000000;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
  display: inline-block;
}

.pricing-button:hover {
  background-color: #4b007d;
  color:#e0d7f3;
}

@media (max-width: 768px) {
  .pricing-card {
    padding: 1rem;
  }
}
