.register-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }
  
  .register-form-title {
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .step-indicator {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .step-dot {
    width: 10px;
    height: 10px;
    background: #ccc;
    border-radius: 50%;
    transition: background 0.3s ease;
  }
  
  .step-dot.active {
    background: #261843;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .register-step {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .register-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .register-input,
  .register-button,
  .register-label {
    padding: 0.8rem;
    border-radius: 4px;
    border: 1px solid #00000024;
    color: #333;
  }
  
  .register-button {
    background-color: #261843;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #916BB8;
  }
  
  .register-summary-list {
    list-style-type: none;
    padding: 0;
  }
  
  .register-summary-list li {
    background: #f0f0f0;
    padding: 0.5rem;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    color: #000;
  }
  
  .register-form-summary-title {
    color: #000;
    font-weight: bold;
  }
  
  .register-form-summary {
    color: #000;
  }
  
  @media (max-width: 768px) {
    .register-form-container {
      padding: 1rem;
    }
  
    .register-actions {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .register-error {
    color: #261843;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    text-align: center;
  }
  
  