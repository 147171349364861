/* src/components/Footer.css */

.footer {
    background-color: #261843;
    color: white;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1); /* Sombra opcional para el footer */
  }
  
  
  .footer-menu {
    display: flex;
    justify-content: center;
    gap: 20px; /* Espacio entre elementos del menú */
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-menu li a {
    text-decoration: none;
    color: #ccc; /* Color de enlaces del menú */
    font-weight: 500;
  }
  
  .footer-menu li a:hover {
    color: #fff; /* Color al pasar el mouse */
  }
  
  .footer-rights {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #aaa;
  }
  
