/* src/pages/Home.css */


.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 120px 20px 00px; /* Ajusta según la altura del header para evitar solapamiento */
    margin-top: 0; /* Quita el margen superior */
  }
  
  

.home-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    padding: 20px;
  }
  
  .grid-big {
    background: linear-gradient(to right, #9E80B4, #8BB5DA); /* Degradado de fondo */
    border-radius: 15px; /* Bordes redondeados */
    padding: 20px;
    color: white;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidad */
  }

  /* src/pages/Home.css */
.home {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos */
    gap: 40px; /* Espacio entre FeatureBox y SupportFeatures */
    padding: 0px;
  }
  
