/* src/components/FeatureBox.css */

.feature-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 20px;
  background:#e0d7f3;
  color: #000000;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.feature-content {
  flex: 1;
  padding-right: 20px;
}

.feature-tag {
  display: inline-block;
  background-color: rgba(42, 42, 42, 0.2);
  color: #3e3e3e;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.feature-content h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.feature-content p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #1d1d1d;
}

.feature-button {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.feature-button:hover {
  background-color: #f0f0f0;
}

.feature-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-image img {
  max-width: 120%;
  border-radius: 20px;
  object-fit: cover;
}

/* src/components/FeatureBox.css */
.feature-list {
  margin-top: 16px;
  padding-left: 20px;
  list-style: none;
  margin-bottom: 80px; /* Añade un margen inferior para separar la lista del botón */
}

.feature-list li {
  margin-bottom: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.feature-list li::before {
  content: '✔️';
  margin-right: 8px;
  color: #4CAF50; /* Color del icono de la lista */
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .feature-box {
    flex-direction: column; /* Cambia la disposición a columna */
    padding: 20px; /* Ajusta el padding para móviles */
  }

  .feature-content {
    padding-right: 0; /* Elimina el padding derecho en móviles */
    text-align: center; /* Centra el texto */
  }

  .feature-content h2 {
    font-size: 1.5rem; /* Reduce el tamaño del título */
  }

  .feature-content p {
    font-size: 0.9rem; /* Ajusta el tamaño del texto */
  }

  .feature-list li {
    font-size: 0.9rem; /* Ajusta el tamaño del texto de la lista */
  }

  /* El botón mantiene su estilo original */
  .feature-button {
    width: auto; /* Mantiene el tamaño original del botón */
    padding: 10px 20px; /* Ajusta el padding del botón */
  }

  .feature-image {
    margin-top: 20px; /* Añade un margen para separar la imagen del texto */
  }

  .feature-image img {
    max-width: 100%; /* La imagen se ajusta a la pantalla */
    border-radius: 15px; /* Reduce el radio de la esquina */
  }
}

/* Estilos para tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .feature-box {
    flex-direction: column; /* Disposición en columna para tablets */
    padding: 30px; /* Ajusta el padding */
  }

  .feature-content {
    text-align: center; /* Centra el texto */
    padding-right: 0;
  }
}