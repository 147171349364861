/* src/components/Navbar.css */

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px; /* Aumenta el padding para dar más espacio al contenido */
    background-color: #000000;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-logo {
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
  
  .navbar-menu {
    flex: 2; /* Da más espacio a los elementos del menú */
    display: flex;
    gap: 50px;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: center; /* Centra los elementos del menú */
  }
  
  .navbar-login {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
  }

  
  .navbar-menu li a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 1rem;
  }
  
  .navbar-login button {
    background-color: white;
    color: black;
    border: none;
    padding: 8px 25px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s;
    
  }
  
  
  .navbar-login button:hover {
    background-color: #f0f0f0;
  }
  
  .navbar-logo img {
    height: 60px;
  }
  
  @media (max-width: 768px) {
    .navbar-menu {
      flex: 1;
      gap: 20px; /* Reduce el espacio entre los elementos del menú */
    }
  
    .navbar-login button {
        padding: 6px 12px; /* Ajusta el padding para asegurar que el botón tenga espacio */
      }
      
  }
  
/* src/components/Navbar.css */

.navbar-login {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Espacio entre los botones de "Registrarme" y "Log In" */
  margin-right: 50px;
}

/* Estilo para el botón "Registrarme" */
.navbar-login .register-button {
  background-color: black;
  color: white;
  border: 2px solid white;
  padding: 8px 25px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.navbar-login .register-button:hover {
  background-color: white;
  color: black;
}

/* Estilo para el botón "Log In" */
.navbar-login button {
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 8px 25px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.navbar-login button:hover {
  background-color: black;
  color: white;
}


/* .navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
} */

.navbar-menu li {
  position: relative;
}
/* Dropdown styles */
.dropdown-menu {
  display: none; /* Por defecto, oculto */
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000; /* Fondo negro */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Sombra */
  list-style: none;
  padding: 10px 0;
  margin: 0;
  z-index: 1000;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0; /* Transparente por defecto */
  transform: translateY(-10px); /* Ligeramente hacia arriba */
}

.dropdown-menu.show {
  display: block; /* Mostrar cuando se activa */
  opacity: 1; /* Visible */
  transform: translateY(0); /* Posición original */
}

.dropdown-menu li {
  padding: 8px 20px;
  white-space: nowrap;
}

.dropdown-menu li a {
  text-decoration: none;
  color: #fff; /* Letras blancas */
  font-size: 0.9rem;
  transition: color 0.3s, background-color 0.3s; /* Suavidad */
}

.dropdown-menu li:hover {
  background-color: #333; /* Fondo gris oscuro */
}

/* Ajuste para el hover en el contenedor */
.navbar-menu li:hover .dropdown-menu {
  display: block;
}

/* Ocultar el menú móvil por defecto */
.mobile-navbar {
  display: none;
}

.mobile-navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #000;
}

.mobile-navbar-logo img {
  height: 40px;
}

.mobile-navbar-hamburger {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

/* Menú desplegable móvil */
.mobile-navbar-menu {
  list-style: none;
  padding: 10px 0;
  margin: 0;
  background-color: #000;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
}

.mobile-navbar-menu li {
  padding: 10px 0;
}

.mobile-navbar-menu a {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  display: block;
  transition: background-color 0.3s;
}

.mobile-navbar-menu a:hover {
  background-color: #333;
}

/* Mostrar menú móvil en pantallas pequeñas */
@media (max-width: 768px) {
  .navbar {
    display: none; /* Ocultar menú de escritorio */
  }

  .mobile-navbar {
    display: block; /* Mostrar menú móvil */
  }
}
