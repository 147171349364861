/* General */
.about-us-page {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Hero Section */
.hero-section {
  display: flex;
  align-items: center;
  padding: 50px;
  background: linear-gradient(to right, #9E80B4, #8BB5DA)
}

  .about-us-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  /* Hero Section */
  .hero-section {
    display: flex;
    align-items: center;
    padding: 50px;
    background: linear-gradient(to right, #9E80B4, #8BB5DA);
    color: white;
  }
  
  .hero-content {
    flex: 1;
    padding-right: 20px;
  }
  
  .hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  

  

  
  /* Team Section */
  .team-section {
    padding: 50px;
    text-align: center;
    background: #f9f9f9;
  }
  
  .team-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .team-grid {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .team-member {
    text-align: center;
    max-width: 300px;
  }
  
  .team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .team-member h3 {
    margin-top: 10px;
    font-size: 1.2rem;
  }
  
  .team-description {
    color: #666;
    margin-top: 5px;
  }
  
  .team-position {
    margin-top: 15px;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .team-member {
    img {
      width: 150px;
      height: auto;
      border-radius: 50%;
    }
  
    .linkedin-image {
      width: 45px;
      height: auto;
      margin-top: 10px;
    }
  }
  
  
  /* Story Section */
  .story-section {
    padding: 50px;
    background: linear-gradient(to right, #9E80B4, #8BB5DA);
    color: white;
    text-align: center;
  }
  
  .story-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .story-section p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  


.hero-content {
  flex: 1;
  padding-right: 20px;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.hero-image {
  flex: 1;
}

.hero-image img {
  width: 100%;
  border-radius: 10px;
}

/* Team Section */
.team-section {
  padding: 50px;
  text-align: center;
  background: #f9f9f9;
}

.team-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.team-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.team-member {
  text-align: center;
  max-width: 300px;
}

.team-member img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.team-member h3 {
  margin-top: 10px;
  font-size: 1.2rem;
}

.team-description {
  color: #666;
  margin-top: 5px;
}

.team-position {
  margin-top: 15px;
  font-size: 1rem;
  font-weight: bold;
}

.linkedin-icon {
  display: inline-block;
  margin-top: 10px;
  font-size: 1.5rem;
  color: #0077b5;
  transition: color 0.3s;
}

.linkedin-icon:hover {
  color: #005582;
}

/* Story Section */
.story-section {
  padding: 50px;
  background: linear-gradient(to right, #9E80B4, #8BB5DA);
  color: white;
  text-align: center;
}

.story-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.story-section p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
