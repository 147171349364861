/* src/components/SupportFeatures.css */

.support-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  background-color: #f4f4f400; /* Ajusta el color de fondo según tu identidad */
}

.support-title {
  font-size: 2rem;
  font-weight: bold;
  color: hwb(0 100% 0% / 0.746);
  margin-bottom: 40px;
  text-align: center;
}

.support-content {
  display: flex;
  max-width: 1200px;
  gap: 0px; /* Disminuye el espacio entre las columnas para acercar las cajas a la imagen */
  width: 100%;
}

.support-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.feature-item {
  background-color: rgba(255, 255, 255, 0.783);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #333;
}

.feature-item p {
  font-size: 1rem;
  color: #666;
}

.support-content {
  display: flex;
  max-width: 1200px;
  gap: 20px; /* Disminuye el espacio entre las columnas para acercar las cajas a la imagen */
  width: 100%;
}

.chat-simulation img {
  width: 100%;
  max-width: 200px; /* Aumenta el tamaño máximo de la imagen para que sea más grande */
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .support-content {
    flex-direction: column; /* Cambia a columna para móviles */
    align-items: center; /* Centra el contenido */
  }

  .support-column {
    flex: none; /* Elimina el flex para que las columnas no se expandan */
    width: 100%; /* Asegura que cada columna ocupe el ancho completo */
    margin-bottom: 20px; /* Espacio entre columnas en móviles */
  }

  .chat-simulation {
    margin: 20px 0; /* Margen superior e inferior para separar la imagen */
  }

  .chat-simulation img {
    max-width: 100%; /* Asegura que la imagen se ajuste al ancho disponible */
  }
}

/* Estilos para tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .support-content {
    flex-direction: column; /* También puede ser en columna para tablets */
  }
}
